$light: #ffffff;
$gray: #e7e9eb;
$gold: #d9b400;
$gob: #a8993e;
$gob2: #8a7e33;

* {
  margin: 0;
  padding: 0;
}

.flex {
  display: flex;
}

.navbar {
  background-color: $light;
  padding: 1rem;
  border-bottom: 1px solid $gray;
}

.sidebar {
  width: 14rem;
  height: 100%;
  border-right: 1px solid $gray;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  overflow-y: auto;

  ul {
    list-style: none;
    padding: 1rem 2rem;

    li {
      margin-bottom: 1rem;
    }

    a {
      text-decoration: none;

      &.active {
        background-color: $gob;
      }
    }

    a:hover { background-color: $gob2; }
  }
}

.footern {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 100%;
  padding-bottom: 4px;
}

.goldtext {
  color: #d9b400;
}

.gobtext {
  color: #a8993e;
}
