@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&display=swap');

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  border: 1px solid #888;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.paragraph {
    white-space: pre-line;
}

.disabled-link {
    pointer-events: none;
}

.listed {
    padding: 0;
    margin: 0;
    list-style: none;
}

.dropzone {
    text-align: center;
    height: 85px;
    padding: 10px;
    border: 3px dashed #eeeeee;
    background-color: #fafafa;
    color: #bdbdbd;
    cursor: pointer;
    margin-bottom: 20px;
}

  .selected-file-wrapper {
    text-align: center;
  }
  
  .selected-file {
    color: #303030;
    font-size: 16px;
    font-weight: bold;
  }

  .selected-file-white {
    color: #fff;
    font-size: 13px;
    font-weight: bold;
  }

.dropzoneSmall { 
    text-align: center;
    width: 10px;
    background-Color: #007bff;
    color: #fff;
    cursor: default;
    padding: 1;
    border-Radius: 3;
}

strong{
    letter-spacing: -0.3px;
    font-weight: 600;
}

.fullscreen {
    text-align: center;
    max-width: max-content;
    margin: auto;
}

.cont {
    margin-left: 14rem;
    padding: 0px 12px;
}

.check {
    text-align: center;
    max-width: 1200px;
    margin: auto;
}

.list {
    max-width: 1600px;
    margin: auto;
}

.submit-form {
    max-width: 1300px;
    margin: auto;
}

.left-form {
    max-width: 600px;
    margin: auto;
    text-align: left;
}

.edit-form {
    max-width: 600px;
    margin: auto;
}

.centered {
    display: flex;
    justify-content: center;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Montserrat", sans-serif;
}

.text-bold {
    font-weight: bold;
}

.boldtext {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Monserrat", sans-serif;
    font-weight: bold;
    font-weight: 700;
}

.bolder{
    font-weight: 700;
    letter-spacing: -0.04em;
    line-height : 30px;
    display: table-cell;
    margin: 0 auto;
    text-align: center;
    vertical-align: middle;
    width: 100%;
}

.lined{
    line-height : 26px;
}

.refugiotext {
    color: #980F5A;
}

.activotext{
    color: #d9b501;
}

.concluidotext{
    color: #269d2d;
}

.linktext {
    color: #0a3f8f;
}

.whitetext {
    color: #FFFFFF;
}

.goldtext {
    color: #D9B400;
}

.gobtext {
    color: #A8993E;
}

.browntext {
    color: #5C3327;
}

.lightbluetext {
    color: #3d98af;
}

.bluetext {
    color: #0d4eaf;
}

.yellowtext {
    color: #9c7917;
}

.greentext {
    color: #11834e;
}

.purpletext {
    color: #7127aa;
}

.redtext {
    color: #b32430;
}

.graytext {
    color: #5d6466;
}

.minitext {
    font-size: 3px;
}

.smalltext {
    font-size: 6px;
}

.mediumtext {
    font-size: 10px;
}

.bigtext {
    font-size: 16px;
}

.biggertext {
    font-size: 24px;
}

.rezisabletext {
    font-size: 1.75vw;
}

.black {
    background-color: #000000;
    padding: 0;
    margin: 0;
}

.gold {
    background-color: #D9B400;
    padding: 0;
    margin: 0;
}

.gob {
    background-color: #A8993E;
    padding: 0;
    margin: 0;
}

.brown {
    background-color: #5C3327;
    padding: 0;
    margin: 0;
}

.nocolor {
    padding: 0;
    margin: 0;
}

.footer {
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    padding-bottom: 10px;
}

.footerp {
    border-style: solid;
    border-width: 2px;
    border-color: #edeced;
    background-color: #f2f3f3;
    padding: 0px 12px;
    position: sticky;
    left: 0;
    bottom: 0;
    right: 0;
    padding-bottom: 5px;
}

.sorttext {
    word-break: break-all;
}

.centeralign {
    display: flex;
    justify-content: center;
    align-items: center;
}

.nivel1 {
    font-weight: bold;
    color: #000;
    background-color: #00b046;
}

.nivel2 {
    font-weight: bold;
    color: #000;
    background-color: #fec000;
}

.nivel3 {
    font-weight: bold;
    color: #000;
    background-color: #ff0000;
}

.btn-dark:hover {
    color: #fff;
    background-color: #333333;
    border-color: #333333;
}

.btn-light {
    border-color: #d1d1d1;
}

.btn-light:hover {
    color: #010100;
    background-color: #d1d1d1;
    border-color: #d1d1d1;
}

.btn-outline-refugio {
    color: #980F5A;
    border-color: #980F5A;
}

.btn-outline-refugio:hover,  .btn-check:checked + .btn-outline-refugio, .btn-outline-refugio:active  {
    color: #fff;
    background-color: #980F5A;
    border-color: #750550;
}

.btn-outline-refugio:focus {
    box-shadow: 0 0 0 0.25rem #980F5A70;
}

.bg-light {
    border-color: #1f1f1f;
}

.bg-refugio {
    color: #ffffff;
    background-color: #980F5A;
}

.bg-purple {
    color: #ffffff;
    background-color: #7127aa;
}

.bg-error {
    color: #ffffff;
    background-color: #b81d09fd;
}

.btn-refugio {
    color: #fff;
    background-color: #980F5A;
    border-color: #750550;
}

.btn-refugio:hover {
    color: #fff;
    background-color: #750550;
    border-color: #750550;
}

.btn-refugio:focus {
    box-shadow: 0 0 0 0.25rem #75055070;
}

.btn-correct-noeffects {
    color: #ffffffdd;
    background-color: #008b07d7;
    border-color: #009b07d7;
}

.btn-correct-noeffects:hover {
    color: #ffffffdd;
    background-color: #008b07d7;
    border-color: #009b07d7;
}

.btn-correct-noeffects:focus {
    box-shadow: 0 0 0 0.25rem #0000;
}

.btn-correct {
    color: #ffffffdd;
    background-color: #008b07d7;
    border-color: #009b07d7;
}

.btn-correct:hover {
    color: #ffffffdd;
    background-color: #009b07d7;
    border-color: #009b07d7;
}

.btn-correct:focus {
    box-shadow: 0 0 0 0.25rem #008b0770;
}

.btn-error {
    color: #ffffffdd;
    background-color: #b81e09d7;
    border-color: #992213d7;
}

.btn-error:hover {
    color: #ffffffdd;
    background-color: #992213d7;
    border-color: #992213d7;
}

.btn-error:focus {
    box-shadow: 0 0 0 0.25rem #b81e0970;
}

.btn-whatsapp2 {
    color: #fff;
    background-color: #075E54;
    border-color: #064941;
}

.btn-whatsapp2:hover {
    color: #fff;
    background-color: #064941;
    border-color: #064941;
}

.btn-whatsapp2:focus {
    box-shadow: 0 0 0 0.25rem #075E5470;
}

.btn-whatsapp {
    color: #fff;
    background-color: #128C7E;
    border-color: #137c70;
}

.btn-telegram {
    color: #fff;
    background-color: #24A1DE;
    border-color: #2286b8;
}

.btn-whatsapp:hover {
    color: #fff;
    background-color: #075E54;
    border-color: #075E54;
}

.btn-whatsapp:focus {
    box-shadow: 0 0 0 0.25rem #128C7E70;
}

.btn-gold {
    color: #fff;
    background-color: #d9b400;
    border-color: #c2a40e;
}

.btn-gold:hover {
    color: #fff;
    background-color: #bba018;
    border-color: #bba018;
}

.btn-gold:focus {
    box-shadow: 0 0 0 0.25rem #d9b40070;
}

.btn-gob {
    color: #fff;
    background-color: #a8993e;
    border-color: #96893a;
}

.btn-gob:hover {
    color: #fff;
    background-color: #756e36;
    border-color: #756e36;
}

.btn-gob:focus {
    box-shadow: 0 0 0 0.25rem #a8993e70;
}

.btn-purple {
    color: #fff;
    background-color: #7127aa;
    border-color: #4c216d;
}

.btn-purple:hover {
    color: #fff;
    background-color: #4c216d;
    border-color: #4c216d;
}

.btn-purple:focus {
    box-shadow: 0 0 0 0.25rem #7127aa70;
}

.btn-outline-purple {
    color: #7127aa;
    border-color: #4c216d;
}

.btn-outline-purple:hover {
    color: #fff;
    background-color: #7127aa;
    border-color: #4c216d;
}

.btn-outline-purple:focus {
    box-shadow: 0 0 0 0.25rem #7127aa70;
}

.btn-brown {
    color: #fff;
    background-color: #5C3327;
    border-color: #3d2118;
}

.btn-brown:hover {
    color: #fff;
    background-color: #3d2118;
    border-color: #3d2118;
}

.btn-brown:focus {
    box-shadow: 0 0 0 0.25rem #5C332770;
}

.alert-nocolor {
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
    margin: auto;
    background-color: #ffffff00;
    border-color: #ffffff00;
}

.alert-message {
    color: #ffffffee;
    background-color: #00000077;
}

.alert-correct {
    margin: auto;
    max-width: 800px;
    color: #ffffffdd;
    background-color: #008b07d7;
    border-color: #009b07d7;
}

.alert-error {
    margin: auto;
    max-width: 800px;
    color: #ffffffdd;
    background-color: #b81e09d7;
    border-color: #b8220ed7;
}

.alert-refugio {
    position: auto;
    color: #570530;
    background-color: #eccddf;
    border-color: #e0a6c7;
}

.alert-purple {
    position: auto;
    color: #502372;
    background-color: #e3c8e2;
    border-color: #d9b4d8;
}


.alert-gob {
    color:  #393311;
    background-color: #E8de93;
    border-color: #A8993E;
}

.alert-size {
    width:400px;
    height:145px;
}

.card-size {
    width:400px;
}

.carousel-size {
    height:595px;
}

.invisible {
    visibility: hidden;
    position: absolute;
    top: -9999px;
}

.nowrap {
    white-space: nowrap;
}

.objectcover {
    object-fit: cover;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

.home,
.solicitudes,
.agregar,
.página {
    display: flex;
    height: 90vh;
    align-items: center;
    justify-content: center;
    font-size: 3rem;
}

label {
    display: block;
    margin-top: 10px;
}

.card-container.card {
    max-width: 350px !important;
    padding: 40px 40px;
}

.card {
    background-color: #f7f7f7;
    padding: 20px 25px 30px;
    margin: 0 auto 25px;
    margin-top: 50px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
    width: 96px;
    height: 96px;
    margin: 0 auto 10px;
    display: block;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
}
